<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="6">
              <a-form-item label="公会编号" prop="guildNo">
                <a-input v-model="queryParam.guildNo" placeholder="请输入公会编号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="6">
              <a-form-item label="姓名" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入姓名" allow-clear/>
              </a-form-item>
            </a-col>
<!--              <a-col :md="8" :sm="6">-->
<!--                <a-form-item label="用户id" prop="userId">-->
<!--                  <a-input v-model="queryParam.userId" placeholder="请输入用户id" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
              <a-col :md="6" :sm="6">
                <a-form-item label="手机号" prop="mobile">
                  <a-input v-model="queryParam.mobile" placeholder="请输入手机号" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="6">
                <a-form-item label="微信号" prop="wxNumber">
                  <a-input v-model="queryParam.wxNumber" placeholder="请输入微信号" allow-clear/>
                </a-form-item>
              </a-col>
<!--              <a-col :md="8" :sm="6">-->
<!--                <a-form-item label="目前所在平台名称" prop="currentPlatformName">-->
<!--                  <a-input v-model="queryParam.currentPlatformName" placeholder="请输入目前所在平台名称" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="6">-->
<!--                <a-form-item label="邀请码" prop="inviteCode">-->
<!--                  <a-input v-model="queryParam.inviteCode" placeholder="请输入邀请码" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
              <a-col :md="6" :sm="6">
                <a-form-item label="申请状态" prop="applyStatus">
                  <a-select placeholder="请选择申请状态" v-model="queryParam.applyStatus" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in dict.type.guild_audit_status" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="礼物结算比例" prop="giftProportion">-->
<!--                  <a-input v-model="queryParam.giftProportion" placeholder="请输入礼物结算比例" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
              <a-col :md="6" :sm="6">
                <a-form-item label="公会名称" prop="guildName">
                  <a-input v-model="queryParam.guildName" placeholder="请输入公会名称" allow-clear/>
                </a-form-item>
              </a-col>
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="是否具有配置礼物结算抽成比例权限" prop="giftProportionPermission">-->
<!--                  <a-input v-model="queryParam.giftProportionPermission" placeholder="请输入是否具有配置礼物结算抽成比例权限" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="与大神续约是否需要大神同意" prop="renewPermission">-->
<!--                  <a-input v-model="queryParam.renewPermission" placeholder="请输入与大神续约是否需要大神同意" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="平台公会结算比例" prop="platformProp">-->
<!--                  <a-input v-model="queryParam.platformProp" placeholder="请输入平台公会结算比例" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="公司/个人名称" prop="companyName">-->
<!--                  <a-input v-model="queryParam.companyName" placeholder="请输入公司/个人名称" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="营业执照" prop="license">-->
<!--                  <a-input v-model="queryParam.license" placeholder="请输入营业执照" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="手续费比例" prop="premiumPercent">-->
<!--                  <a-input v-model="queryParam.premiumPercent" placeholder="请输入手续费比例" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['biz:guild:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['biz:guild:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['biz:guild:audit']">-->
<!--          <a-icon type="edit" />审核-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['biz:guild:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['biz:guild:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :applyStatusOptions="dict.type.guild_audit_status"
        @ok="getList"
      />
      <audit-form
        ref="auditForm"
        :applyStatusOptions="dict.type.guild_audit_status"
        @ok="getList"
      />
      <edit-guild-detail ref="editGuildDetail" @ok="getList" />
      <edit-guild-signing ref="editGuildSigning" @ok="getList" />
      <create-guild-acount-form  ref="createGuildAcountForm"  @ok="getList" />
      <!-- 数据展示  -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="guildLogo" slot-scope="text, record">

           <ImageViewer class="imageViewer"   v-if="record.guildLogo"  ref="previewImg"
                        v-for="(guildLogo,imgIndex) in record.guildLogo.split(',')"
                        :imageUrl="guildLogo"
                        preview="图片" />
        </span>
        <span slot="isCompany" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_yes_no_number']" :value="record.isCompany"/>
        </span>
        <span slot="isRecommend" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_yes_no_number']" :value="record.isRecommend"/>
        </span>

        <span slot="applyStatus" slot-scope="text, record">
          <dict-tag :options="dict.type['guild_audit_status']" :value="record.applyStatus"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--           <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['biz:guild:edit']">-->
<!--            <a-icon type="edit" />修改-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['biz:guild:audit']" />-->
          <a v-if="record.applyStatus=='0'" @click="$refs.auditForm.handleAudit(record, undefined)" v-hasPermi="['biz:guild:audit']">
            <a-icon type="audit"   />审核  <a-divider type="vertical" v-hasPermi="['biz:guild:edit']" />
          </a>
           <a v-if="record.applyStatus=='1'&&record.sysUserId==null&&record.companyType==1" @click="$refs.createGuildAcountForm.handleAdd(record)" v-hasPermi="['biz:guild:add']">
            <a-icon type="plus"   />创建公会账号  <a-divider type="vertical" v-hasPermi="['biz:guild:edit']" />
          </a>
          <a @click="$refs.editGuildDetail.handleUpdate(record,1)"  v-hasPermi="['biz:guild:edit']">
               公会说明
           </a>
            <a-divider type="vertical" v-hasPermi="['biz:guild:edit']" />
          <a @click="$refs.editGuildDetail.handleUpdate(record,2)"  v-hasPermi="['biz:guild:edit']">
               签约协议
           </a>
<!--          <a-divider type="vertical" v-hasPermi="['biz:guild:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['biz:guild:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageGuild,listGuild, delGuild } from '@/api/biz/guild'
import CreateForm from './modules/CreateForm'
import CreateGuildAcountForm from './modules/CreateGuildAcountForm'
import AuditForm from './modules/AuditForm'
import { tableMixin } from '@/store/table-mixin'
import ImageViewer from '@/components/Viewer/ImageViewer.vue'
import EditGuildDetail from '@/views/biz/guild/editGuildDetail.vue'
import EditGuildSigning from '@/views/biz/guild/editGuildSigning.vue'
export default {
  name: 'Guild',
  components: {
    EditGuildSigning,
    EditGuildDetail,
    ImageViewer,
    CreateForm,AuditForm,CreateGuildAcountForm
  },
  mixins: [tableMixin],
  dicts: ['guild_audit_status','sys_yes_no_number'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        guildNo: null,
        name: null,
        userId: null,
        mobile: null,
        wxNumber: null,
        currentPlatformName: null,
        applyLabelIds: null,
        isCompany: null,
        streamImg: null,
        wxGroupImg: null,
        collectionImg: null,
        inviteCode: null,
        applyStatus: null,
        rejectReason: null,
        giftProportion: null,
        guildName: null,
        introduction: null,
        giftProportionPermission: null,
        isRecommend: null,
        guildLogo: null,
        renewPermission: null,
        platformProp: null,
        companyType: null,
        companyName: null,
        license: null,
        premiumPercent: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '主键id',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '公会编号',
          dataIndex: 'guildNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '姓名',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公会LOGO',
          dataIndex: 'guildLogo',
          ellipsis: true, scopedSlots: {customRender: "guildLogo"},
          align: 'center'
        },
        {
          title: '公会名称',
          dataIndex: 'guildName',width: 120,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          ellipsis: true,width: 120,
          align: 'center'
        },
        {
          title: '微信号',
          dataIndex: 'wxNumber',
          ellipsis: true,width: 100,
          align: 'center'
        },
        {
          title: '目前所在平台',
          dataIndex: 'currentPlatformName',
          ellipsis: true,width: 100,
          align: 'center'
        },
        {
          title: '入驻品类',
          dataIndex: 'applyLabelTitle',
          ellipsis: true,width: 150,
          align: 'center'
        },
        {
          title: '具备公司资质',
          dataIndex: 'isCompany',
          ellipsis: true,scopedSlots:{ customRender: 'isCompany' },
          align: 'center'
        },
        {
          title: '公会账号',
          dataIndex: 'accountNo',
          ellipsis: true,width: 150,
          align: 'center',
          customRender: function (value) {
            if (value==undefined||value==""){
              return "未创建"
            }else{
              return value
            }
          }
        },
        // {
        //   title: '其他补充信息',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '目前所在平台最新三个月后台流水截图',
        //   dataIndex: 'streamImg',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '公会后台团队人数截图以及团队的微信群截图',
        //   dataIndex: 'wxGroupImg',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '上个月平台给您公会打款的收款截图',
        //   dataIndex: 'collectionImg',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '邀请码',
        //   dataIndex: 'inviteCode',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '申请状态',
          dataIndex: 'applyStatus',
          scopedSlots: { customRender: 'applyStatus' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '驳回原因',
        //   dataIndex: 'rejectReason',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '礼物结算比例',
        //   dataIndex: 'giftProportion',
        //   ellipsis: true,
        //   align: 'center'
        // },

        // {
        //   title: '公会简介',
        //   dataIndex: 'introduction',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '是否具有配置礼物结算抽成比例权限',
        //   dataIndex: 'giftProportionPermission',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '是否推荐',
        //   dataIndex: 'isRecommend',
        //   ellipsis: true,scopedSlots:{ customRender: 'isRecommend' },
        //   align: 'center'
        // },

        // {
        //   title: '与大神续约是否需要大神同意',
        //   dataIndex: 'renewPermission',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '平台公会结算比例',
        //   dataIndex: 'platformProp',
        //   ellipsis: true,
        //   align: 'center'
        // },
         {
          title: '公会类型',
          dataIndex: 'companyType',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value==0){
              return "个人"
            }else if (value==1){
              return "公司"
            }else{
              return "未知"
            }
        }
        },
        // {
        //   title: '公司/个人名称',
        //   dataIndex: 'companyName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '营业执照',
        //   dataIndex: 'license',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '手续费比例',
        //   dataIndex: 'premiumPercent',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center' ,width: 160
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '10%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {


    /** 查询公会(入驻)列表 */
    getList () {
      this.loading = true
     pageGuild(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        guildNo: undefined,
        name: undefined,
        userId: undefined,
        mobile: undefined,
        wxNumber: undefined,
        currentPlatformName: undefined,
        applyLabelIds: undefined,
        isCompany: undefined,
        streamImg: undefined,
        wxGroupImg: undefined,
        collectionImg: undefined,
        inviteCode: undefined,
        applyStatus: undefined,
        rejectReason: undefined,
        giftProportion: undefined,
        guildName: undefined,
        introduction: undefined,
        giftProportionPermission: undefined,
        isRecommend: undefined,
        guildLogo: undefined,
        renewPermission: undefined,
        platformProp: undefined,
        companyType: undefined,
        companyName: undefined,
        license: undefined,
        premiumPercent: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delGuild(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/guild/export', {
            ...that.queryParam
          }, `公会(入驻)_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
<style lang="less" scoped>
//图片样式
.imageViewer{
  width: 48px;height:48px;display: flex;justify-content: center;margin: auto;
}
</style>
